import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
// import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";

export default function March() {
  return (
    <Layout>
      <Helmet title="March (Hurry Up & Wait) | Michael Weslander" />

      <h1>March (Hurry Up & Wait)</h1>
      <h2>Michael Weslander</h2>
      <h4>Key: A</h4>
      <h4>Tempo: 82</h4>
      <Link to="/">List</Link>

      <Columns columns="2">
        <Chorus>
          <p>
            <b>D A E (riff x2)</b>
          </p>
        </Chorus>

        <Verse>
          <p>
            <Ch>F#m</Ch>Wouldn’t you <Ch>A</Ch>know
          </p>
          <p>
            It’s an<Ch>D</Ch>ot<Ch>A</Ch>her <Ch>A</Ch>day
          </p>
          <p>
            In a <Ch>F#m</Ch>treadmill <Ch>A</Ch>week (maze), still
          </p>
          <p>
            <Ch>D</Ch>Time marc<Ch>A</Ch>hes <Ch>A</Ch>on
          </p>
        </Verse>

        <Verse>
          <p>
            <Ch>F#m</Ch>And we’re the<Ch>A</Ch> lucky ones
          </p>
          <p>
            <Ch>D</Ch>Alive <Ch>A</Ch>and <Ch>A</Ch>well
          </p>
          <p>
            But it’s <Ch>F#m</Ch>hard to keep perspec<Ch>A</Ch>tive
          </p>
          <p>
            Or <Ch>D</Ch>couldn’t <Ch>A</Ch>you <Ch>A</Ch>tell
          </p>
        </Verse>

        <Chorus>
          <p>
            <b>D A E (riff)</b>
          </p>
          <p>Hurry up & wait for it</p>
          <p>Hurry up &</p>
        </Chorus>

        <Verse>
          <p>
            <Ch>F#m</Ch>Sorry, I<Ch>A</Ch> spaced
          </p>
          <p>
            I’ve been <Ch>D</Ch>lost in <Ch>A</Ch>the <Ch>A</Ch>same place
          </p>
          <p>
            With <Ch>F#m</Ch>lingering <Ch>A</Ch>guilt
          </p>
          <p>
            From moments <Ch>D</Ch>years <Ch>A</Ch>be<Ch>A</Ch>fore
          </p>
        </Verse>

        <Verse>
          <p>
            With <Ch>F#m</Ch>all this time to <Ch>A</Ch>tell myself
          </p>
          <p>
            To <Ch>D</Ch>let <Ch>A</Ch>it <Ch>A</Ch>go
          </p>
          <p>
            Does it <Ch>F#m</Ch>hurt to listen <Ch>A</Ch>back
          </p>
          <p>
            When I re<Ch>D</Ch>wind <Ch>A</Ch>the <Ch>A</Ch>show
          </p>
        </Verse>

        <Chorus>
          <p>
            <b>D A E (riff)</b>
          </p>
          <p>Hurry up & wait for it</p>
          <p>Hurry up I</p>
        </Chorus>

        <Bridge>
          <p>
            <Ch>Bm</Ch>Can’t stop <Ch>A</Ch>marching<Ch>E</Ch>
          </p>
          <p>
            <Ch>Bm</Ch>Can’t stop <Ch>A</Ch>marching<Ch>E</Ch>
          </p>
          <p>
            <Ch>Bm</Ch>Can’t stop <Ch>A</Ch>marching<Ch>E</Ch>
          </p>
          <p>
            <Ch>E</Ch>Not really <Ch>F#m</Ch>moving
          </p>
          <p>
            <Ch>G</Ch>Worn out all the <Ch>D</Ch>same
          </p>
        </Bridge>

        <Chorus>
          <p>
            <b>D A E (riff x2)</b>
          </p>
        </Chorus>

        <Verse>
          <p>
            <Ch>F#m</Ch>Wouldn’t you <Ch>A</Ch>know
          </p>
          <p>
            It’s <Ch>D</Ch>ano<Ch>A</Ch>ther <Ch>A</Ch>day
          </p>
          <p>
            The <Ch>F#m</Ch>morning feels like<Ch>A</Ch> maybe
          </p>
          <p>
            Evening <Ch>D</Ch>seems <Ch>A</Ch>oka<Ch>A</Ch>y
          </p>
        </Verse>

        <Verse>
          <p>
            <Ch>F#m</Ch>In retrospect I <Ch>A</Ch>wonder
          </p>
          <p>
            If we’ll <Ch>D</Ch>miss <Ch>A</Ch>the <Ch>A</Ch>change of pace
          </p>
          <p>
            For <Ch>F#m</Ch>now I guess
          </p>
          <p>
            We’ll <Ch>A</Ch>let that broken <Ch>D</Ch>rec<Ch>A</Ch>ord{" "}
            <Ch>A</Ch>play
          </p>
        </Verse>

        <Chorus>
          <p>
            <b>D A E (riff)</b>
          </p>
          <p>Hurry up & wait for it</p>
          <p>Hurry up wait</p>
          <p>Hurry up & wait for it</p>
          <p>Hurry up wait</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}





